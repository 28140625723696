@import './variable.less';

.theme-bg-color {
  [data-theme='dark'] & {
    background-color: @dark-background-color;
  }
  [data-theme='light'] & {
    background-color: @light-background-color;
  }
}

.theme-color {
  [data-theme='dark'] & {
    color: @dark-color;
  }
  [data-theme='light'] & {
    color: @light-color;
  }
}

.theme-card-bg-color {
  [data-theme='dark'] & {
    background-color: @dark-card-background-color;
  }
  [data-theme='light'] & {
    background-color: @light-card-background-color;
  }
}

.theme-border {
  [data-theme='dark'] & {
    border: @dark-border;
  }
  [data-theme='light'] & {
    border: @light-border;
  }
}

.theme-point-color {
  [data-theme='dark'] & {
    color: @dark-point-color;
  }
  [data-theme='light'] & {
    color: @light-point-color;
  }
}

.theme-link-color {
  [data-theme='dark'] & {
    color: @dark-link-color;
  }
  [data-theme='light'] & {
    color: @light-link-color;
  }
}

.content-bg {
  [data-theme='dark'] & {
    background-color: #210f55;
  }
  [data-theme='light'] & {
    background-color: @light-background-color;
  }
}

.colorful-border {
  background-clip: padding-box, border-box;
  background-origin: padding-box, border-box;
  border: 2px solid transparent;
  [data-theme='dark'] & {
    background-image: linear-gradient(#29277d, #32188b 45%),
      linear-gradient(135deg, #3549ff, #57c3ff, #9f25ff);
  }
  [data-theme='light'] & {
    background-image: linear-gradient(#f3f9ff, #fffdff 45%),
      linear-gradient(135deg, #ccbfff, #a3d3ff, #ad9fff);
  }
}

.tab-shadow {
  [data-theme='dark'] & {
    box-shadow: 0 3px 8px 1px #16086e;
  }
  [data-theme='light'] & {
    box-shadow: 0 3px 8px 1px #e2e4ff;
  }
}
